import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppConstants {
    public ROLES_PERMISSION = {
      "roles": {
          "owner": [
              "create restaurant",
              "update restaurant",
              "update location",
              "create user",
              "update user",
              "verify user",
              "view user",
              "add sales entry",
              "update sales entry",
              "view sales entry",
              "create schedule",
              "update schedule",
              "view schedule",
              "create food item",
              "update food item",
              "delete food item",
              "view food item",
              "create timesheet",
              "view timesheet",
              "update timesheet",
              "view payrollsheet",
              "view comment",
              "view report",
              "create vendor",
              "view vendor",
              "update vendor"
          ],
          "general manager": [
              "update location",
              "create user",
              "update user",
              "verify user",
              "view user",
              "add sales entry",
              "update sales entry",
              "view sales entry",
              "create schedule",
              "update schedule",
              "view schedule",
              "create food item",
              "update food item",
              "delete food item",
              "view food item",
              "create timesheet",
              "view timesheet",
              "update timesheet",
              "view payrollsheet",
              "view report",
              "create vendor",
              "view vendor",
              "update vendor"
          ],
          "manager": [
              "create user",
              "verify user",
              "add sales entry",
              "update sales entry",
              "view sales entry",
              "create schedule",
              "update schedule",
              "view schedule",
              "create food item",
              "update food item",
              "delete food item",
              "view food item",
              "create timesheet",
              "view timesheet",
              "update timesheet",
              "view payrollsheet"
          ],
          "shift-super": [
              "add sales entry",
              "view sales entry",
              "view schedule",
              "view food item",
              "create timesheet",
              "view timesheet",
              "update timesheet",
              "view payrollsheet"
          ],
          "cashier": [
              "view sales entry",
              "view schedule",
              "view food item"
          ],
          "cook": [
              "view schedule",
              "view food item"
          ],
          "dishwasher": [
              "view schedule"
          ]
      }
  }
  
    public EDUCATION_LIST = [
        { id: 0, value: 'Secondary School' },
        { id: 1, value: 'High School' },
        { id: 2, value: 'College Certificate Diploma' },
        { id: 3, value: 'Graduate / Post Graduate' },
      ]
    public GENDER = [
        {type: 'Male', value: 'M'},
        {type: 'Female', value: 'F'}
      ]
}