import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { InvalidLoginError } from '../errors/invalid-login-error';
import { UnauthorisedAccessError } from '../errors/unathorised-access-error';
import { AppError } from '../errors/app-error';
import { CommonUtils } from './common-utils';
import { Vendor } from '../models/vendor';
@Injectable({
  providedIn: 'root',
})
export class VendorService {
  private url = environment.api_prefix + '/vendor';
  private localVendorVar = 'Vendors';

  constructor(private authService: AuthService, private http: HttpClient) {}

  createLocalVendors() {
    if (!localStorage.getItem(this.localVendorVar)) {
      this.getVendorsFromAPIAndSave();
    }
  }

  getVendorsFromAPIAndSave() {
    this.getVendorsForRestaurant(
      this.authService.getActiveRestaurant().objectId
    ).subscribe((response) => {
      var foodItems = response;
      localStorage.setItem(this.localVendorVar, JSON.stringify(foodItems));
    });
  }

  updateForVendorChange() {
    this.getVendorsFromAPIAndSave();
  }

  get vendors() : Vendor[]{   
    var vendorList = JSON.parse(localStorage.getItem(this.localVendorVar));
    var vendorObjList : Vendor []= [];
    for(var vendor of vendorList){
      var vendorObj = new Vendor();
      vendorObj.fillValuesFromObj(vendor);
      vendorObjList.push(vendorObj);
    }
    return vendorObjList
  }

  createVendor(vendorData) {
    var payload = {};
    for (var key of Object.keys(vendorData)) {
      payload[CommonUtils.camelToSnakeCase(key)] = vendorData[key];
    }
    return this.http
      .post(
        this.url + '/create',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  getVendorsForRestaurant(restaurant) {
    return this.http.get(
      this.url + '/restaurant/' + restaurant + '/all',
      this.authService.getBasicAuthHeaderForAPI()
    );
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 422) return throwError(new InvalidLoginError(error));
    if (error.status === 403)
      return throwError(new UnauthorisedAccessError(error));
    if (error.status === 500) return throwError(new AppError(error));
    if (error.status === 401)
      return throwError(new UnauthorisedAccessError(error));
    return throwError(new AppError(error));
  }
}