export class Vendor {
    objectId: string;
    name: string;
    account: string;
    type: string;
    contactName: string;
    contactNumber: string;
    address: VendorAddress[];
    metaData: string;
    assignedRestaurant: Array<{name: string, objectId: number}>;
    lastService: any;
    serviceFrequency: string;
    description: string;
    constructor() {
        this.objectId = null; this.name = null; this.account = null; this.type = null; this.contactName = null;
        this.contactNumber = null; this.address = []; this.description = ""; this.metaData = null;
        this.assignedRestaurant = []; this.lastService = null; this.serviceFrequency = null;
     }

     fillValuesFromObj(valObj){
        for(var key of Object.keys(this)){
            if(valObj[key]!=""){
              this[key] = valObj[key];
            }
        }
    }

    static getServiceFrequency() {
        return ["Weekly", "Bi-weekly", "Monthly", "Quarterly", "Half-yearly", "Annually", "N/A"]
    }

    hasValidateAddressEntries(){
        if(this.address.length == 0)
            return false;
         for(var item of this.address){
             if(item.validateAddressEntry())
                return true;
         }
         return false;
    }
}

export class VendorAddress {
    city: string;
    country: string;
    postalcode: string;
    province: string;
    streetnumber: string;
    streetname: string;
    unit: string
    constructor() {
        this.city = null; this.country = null; this.postalcode = null; this.province = null;
        this.streetnumber = null; this.streetname = null; this.unit = null;
    }

    validateAddressEntry() : boolean{
        return this.city==null || this.country==null || this.postalcode==null || this.postalcode.length!=6 || this.province==null || this.streetname==null || this.streetnumber==null;
    }
}